$black: #1e1f13;
$white: #ffffff;

.container {
    flex-grow: 1;
    margin: 0 auto;
    padding: 0 32px;
    position: relative;
    width: auto;
    height: 100%;
    &.fluid {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
    @media (min-width: 1024px) {
      max-width: 960px;
    }
    @media (min-width: 1216px) {
      max-width: 1300px;
    }
    @media (min-width: 1408px) {
      max-width: 1700px;
    }
  
    .row {
      display: flex;
      align-items: center;
      &.space-between {
        justify-content: space-between;
      }
      &.center {
        justify-content: center;
      }
    }
  }
  
  header {
    padding: 3rem;
    font-size: 16px;
    position: fixed;
    z-index: 99;
    width: 100%;
    font-weight: "700";
    .container {
      .row {
        height: 128px;
        .logo {
          a {
            color: $white;
            text-decoration: none;
          }
        }
        .menu {
          cursor: pointer;
          border-radius: 100%;
          border: 1px solid $white;
          height: 80px;
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in-out;
          &:hover {
            color: $white;
            background: $black;
          }
        }
      }
    }
  }