.hero-container{
    .hero-text-section{
        max-width: 10.5em;
        width: calc(100% - 32px);
        margin: 0 auto;
        padding-bottom: 300px;
        padding-top: 300px;
        text-align: center;
        font-size: 2vw;
        font-weight: 600;
        h1{
            display: inline-block;
        }      

    }

    .hero-collage {
        display: grid;
        grid-template-columns: 35% 1fr 35%;
        max-width: 95%;
        margin: 0 auto;
        margin-bottom: 10vh;

        .left-column{
            grid-area: 1 / 1 /span 1 /span 1;
            .hero-element {
                grid-area: 1 / 2/ span 5 / span 1;

                &:first-child{
                    grid-area: 2 / 1/ span 5/ span 1;
                }

                &:last-child{
                    grid-area: 2 / 3/ span 6/ span 1;
                }
            }
        }

        .right-column{
            grid-area: 1 / 3 /span 1 /span 1;
            .hero-element {
                grid-area: 1 / 2/ span 5 / span 1;

                &:first-child{
                    grid-area: 2 / 1/ span 5/ span 1;
                }

                &:last-child{
                    grid-area: 2 / 3/ span 6/ span 1;
                }
            }
        }
        .left-column,
        .right-column{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(6, 1fr);
            column-gap: 20px;
            height: 400px;
        }

    }

    .hero-element{
        overflow: hidden;
        border-radius: 20px;

        video, 
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .hero-media{
        position: absolute;
        height: 764px;
    }

    .hero-phone-black{
        position: absolute;
        width: 364px;
        height: 650px;
        top: 0;
        left: 50%;
        transform: translateX(-40%) scale(1.5);

        .hero-phone-template{
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;

            video{
                position: absolute;
                width: 64%;
                height: auto;
                top: 5%;
                left: 9%;
                border-bottom: 20px;
                overflow: hidden;
            }
        }
    }

    .hero-usedBy{
        display: flex;
        justify-content: space-between;
        margin-top: 234px;
        padding-top: 234px;
        margin-bottom: 300px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1304px;
        width: calc(100% - 48px);
        position: relative;
        color: white;

        .hero-usedBy-copy{
           margin-bottom: 0;
           padding-left: 52px;
           width: 390px; 
           font-size: 20px;
           font-weight: 400;
           line-height: 1.4 ;
        }
    }
}