$main: #f0d8bb;
$white: #ffffff;
$black: #1e1f13;

* {
  box-sizing: border-box;
  font-family: "HelveticaNeue-CondensedBold", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 900;
}

body {
  padding: 0;
  margin: 0;
  background-color: $main;
  color: $white;
  &:before {
    animation: grain 8s steps(10) infinite;
    content: "";
    height: 100%;
    left: -50%;
    opacity: 0.3;
    position: fixed;
    top: -100%;
    width: 100%;
  }
  &.no-scroll {
    overflow-y: hidden;
  }
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}

//single
.single {
  .container {
    .top-row {
      height: 50vh;
      width: 100%;
      align-items: flex-end;
      .top {
        padding-bottom: 40px;
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .location {
            span:nth-child(2) {
              margin-left: 16px;
            }
          }
          .mua {
            text-transform: uppercase;
          }
        }
        .model {
          overflow: hidden;
          .first {
            margin-right: 72px;
          }
          span {
            display: inline-block;
            position: relative;
            font-weight: 400;
            font-size: 150px;
            font-family: "Maragsa";
            // @media (max-width: 800px) {
            //   font-size: 50px;
            // }
            @media (max-width: 1440px) {
              font-size: 100px;
            }
          }
        }
      }
    }
    .bottom-row {
      height: 50vh;
      position: relative;
      .bottom {
        height: 100%;
        width: 100%;
        .thumbnail-single {
          width: 100%;
          height: 800px;
          margin: 0 auto;
          overflow: hidden;
          position: absolute;
          left: 0;
          right: 0;
          .frame-single {
            img {
              position: absolute;
              width: 100%;
            }
          }
        }
      }
      .scroll-for-more {
        position: absolute;
        bottom: 200px;
        left: 200px;
        z-index: 20;
        .icon {
          svg {
            height: auto;
            width: 28px;
          }
        }
        .text {
          margin-top: 8px;
          color: $white;
          text-transform: uppercase;
        }
      }
    }
  }
}

.detailed-information {
  margin-top: 200px;
  .container {
    .row {
      justify-content: space-between;
      align-items: flex-start;
      h2 {
        font-size: 28px;
      }
      p {
        font-family: "Helvetica Neue";
        font-size: 16;
        line-height: 28px;
        font-weight: 400;
        width: 800px;
      }
    }
  }
}

.footer{
  height: 70vh;
  background-color: #285ede;
  display: flex;
  align-items: flex-end;
  padding: 100px;
  color: white;
  font-size: 3rem;
  font-weight: 600;
}
